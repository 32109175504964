import React from 'react';
import moment from 'moment';
import { Box, Flex, SimpleGrid, Button, Text,  
    Modal, ModalOverlay, ModalHeader, ModalBody, ModalContent, ModalFooter, FormLabel, 
    Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { RangeCalendarPanel, Month_Names_Short, Weekday_Names_Short } from "chakra-dayzed-datepicker";
import { FormattedMessage, useIntl } from 'react-intl';
import { localeFormatDate } from '../_helpers/date';
import { CloseIcon } from '../_images/icons/CloseIcon';


export const DateRangeModal = ({isOpen, onClose, onSelected}) => {
    const [ selectedDates, setSelectedDates ] = React.useState([new Date(), new Date()]);
    const { formatMessage, locale } = useIntl();

    const handleClose = () => {
        setSelectedDates([null, null]);
        onClose();
    }

    const handleSubmit = () => {
        onSelected(selectedDates);
        onClose();
    }

    const handleDatePickerChange = ({selectable, date}) => {
        console.log("startDate, endDate ", selectedDates);
        let newDates = [...selectedDates];
        if (selectedDates.length) {
          if (selectedDates.length === 1) {
            let firstTime = selectedDates[0];
            if (firstTime < date) {
              newDates.push(date);
            } else {
              newDates.unshift(date);
            }
            setSelectedDates(newDates);
            return;
          }
    
          if (newDates.length === 2) {
            setSelectedDates([date]);
            return;
          }
        } else {
          newDates.push(date);
          setSelectedDates(newDates);
        }
    }

    const handleStartDateClick = () => {
        setSelectedDates([null, null]);
    }

    const handleEndDateClick = () => {    
        setSelectedDates([null, null]);
        //setSelectedDates([selectedDates[0], null])
    }

    const modalPaddingX = '20px';
    const modalPaddingY = '20px';

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent minW={'700px'}>
                <ModalHeader
                    boxShadow={'0px -2px 15px 0px rgba(0, 0, 0, 0.08)'}
                    backgroundColor={'#fff'}
                    borderTopRadius={'10px'}
                >
                    <Flex w={'full'} justify={'center'} align={'center'} px={modalPaddingX} >
                        <Text
                        color={'#12171A'}
                        fontSize={'16px'}
                        >
                        <FormattedMessage
                        id={'downloadReportPage.modal.dateeRange.title'}
                        defaultMessage={'Select Date Range'}
                        />
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box py={modalPaddingY} px={modalPaddingX} >
                        <Flex w={'full'} direction={'column'} align={'center'}>
                            <SimpleGrid w={'full'} columns={[2]} columnGap={'20px'}>
                                <Box>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={'downloadReportPage.modal.dateeRange.startDate'}
                                            defaultMessage={'Start Date'}
                                        />
                                    </FormLabel>
                                    <InputGroup>
                                    <Input 
                                        readOnly
                                        variant={'rounded'}
                                        name={'startDate'}
                                        _hover={{cursor: 'default'}}
                                        placeholder={formatMessage({id: 'downloadReportPage.modal.dateeRange.startDatePlaceholder', defaultMessage: 'Select a Date'})}
                                        value={selectedDates[0] ? localeFormatDate(selectedDates[0], locale, 'll') : ''}
                                    />
                                    { selectedDates[0] && ( 
                                        <InputRightElement 
                                            children={<CloseIcon />} 
                                            onClick={handleStartDateClick} 
                                            _hover={{cursor: 'pointer'}}
                                        />
                                    )}
                                    </InputGroup>
                                </Box>
                                <Box>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={'downloadReportPage.modal.dateeRange.endDate'}
                                            defaultMessage={'End Date'}
                                        />
                                    </FormLabel>

                                    <InputGroup>
                                    <Input 
                                        readOnly
                                        variant={'rounded'}
                                        name={'endDate'}
                                        _hover={{cursor: 'default'}}
                                        placeholder={formatMessage({id: 'downloadReportPage.modal.dateeRange.endDatePlaceholder', defaultMessage: 'End Date'})}
                                        value={selectedDates[1] ? localeFormatDate(selectedDates[1], locale, 'll') : ''}
                                    />
                                    { selectedDates[1] && (
                                        <InputRightElement 
                                            children={<CloseIcon />} 
                                            onClick={handleEndDateClick} 
                                            _hover={{cursor: 'pointer'}}
                                        />
                                    )}
                                    </InputGroup>
                                </Box>

                            </SimpleGrid>
                            <Box w={'full'} minH={'310px'} mt={'20px'}>
                                <RangeCalendarPanel    
                                    selected={selectedDates} // momentPropTypes.momentObj or null,
                                    dayzedHookProps={{
                                    showOutsideDays: false,
                                    onDateSelected: handleDatePickerChange,
                                    selected: selectedDates,
                                    monthsToDisplay: 2,
                                    }}
                                    configs={{
                                        dateFormat: 'll',
                                        monthNames: Month_Names_Short,
                                        dayNames: Weekday_Names_Short,
                                        firstDayOfWeek: 0,
                                    }}
                                    propsConfigs={{
                                        dateNavBtnProps: {
                                          //colorScheme: "progressColor"
                                        },
                                        dayOfMonthBtnProps: {
                                          defaultBtnProps: {
                                            _hover: {
                                              background: 'progressColor.600',
                                            }
                                          },
                                          isInRangeBtnProps: {
                                            background: "progressColor.400",
                                          },
                                          selectedBtnProps: {
                                            background: "progressColor.400",
                                            //color: "green",
                                          },
                                        },
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter py={modalPaddingY}>
                    <SimpleGrid columns={[2]} w={'full'} gap={'20px'} px={modalPaddingX} >
                        <Flex w={'full'}>
                            <Button variant={'outlineDefault'}  flexGrow={1} onClick={handleClose}>
                                <FormattedMessage
                                    id={'downloadReportPage.modal.dateeRange.cancel'}
                                    defaultMessage={'Cancel'}
                                />
                            </Button>
                        </Flex>
                        <Flex w={'full'}>
                            <Button 
                                flexGrow={1} 
                                onClick={handleSubmit}
                                isDisabled={selectedDates.length !== 2}
                            >
                                <FormattedMessage
                                    id={'downloadReportPage.modal.dateeRange.download'}
                                    defaultMessage={'Apply'}
                                />
                            </Button>
                        </Flex>
                    </SimpleGrid>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}