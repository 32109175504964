import { REQUEST_TYPES, URLS, GENDERS, LOCATIONS, MOMENT_DATE_FORMAT, GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID } from '../_config';
import React, {useState, useEffect, useRef} from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { find, upperFirst, toUpper, cloneDeep } from 'lodash';
import cn from 'classnames';

import { Flex, Text, Link, Image, Button } from '@chakra-ui/react';

import logo from '../_images/logoPDHPrimary.png';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { LineLogin } from '../LoginPage/components/LineLogin';

import { LoadingEllipses } from '../_components/LoadingEllipses';

import googleIcon from '../_images/GoogleLogo.svg'
import facebookIcon from '../_images/FacebookIcon.svg';
import onboardingImage from '../_images/GetStarted.png'
import { SelectChevronDown } from '../_images/icons/SelectChevronDown';

import { customAlphabet } from 'nanoid';
import { convertToAPIValues } from '../_helpers';
import { required, sleep, isEmpty } from '../_helpers/utils';

import { constants as authConstants } from '../Authentication/_constants';
import { actions } from '../Authentication/_actions';
import { services } from '../Authentication/_services';
import { actions as appsettingsActions } from '../Settings/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { getCountries, getRegions} from '../Settings/_selectors';
import {
  getIntlMessages,
  getIntlLocale,
  getIsLoggedInFlag,
  getIsChatLoaded
}
from '../Authentication/_selectors';

import {
  ErrorLabel,
  ErrorSpan,
  StyledButton,
  StyledLoadingButtonLoading
}
from '../_styles/forms'

import {
  PageWrapper,
  Wrapper,
  StyledActivationWrapper,
  InitialWrapper,
  BasicInformationWrapper,
  StyledRegistrationSignUpWrapper,
  StyledActivationMessage,
  StyledCompanyLogo,
  StyledCountrySelection,
  BasicInformationTitle,
  StyledFormRow,
  StyledLabel,
  StyledInputWithOverlay,
  StyledInputLayout,
  StyledInput,
  FormRadioGroup,
  FormRadioLabel,
  RequestTypeField,
  StyledDayPickerInputWrapper,
  StyledDayPickerInput,
  StyledDayPickerIconContainer,
  StyledOnboardingImage,
  StyledRegisterButton,
  StyledExtraDescriptionLink,
  StyledExtraDescription,
  StyledRegistrationSignUpColumn,
  StyledGoogleButton,
  StyledFacebookButton,
  StyledLoginButton,
  StyledTerms,
  StyledSpacing,
  StyledIntialActions,
  Divider,
  errorCss
}
from './styles';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import storage from '../_helpers/storage';
import { LineLoginContainer } from './LineLoginContainer';

const selectStyles = {
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    return { ...provided, color };
  },
  valueContainer: (provided, state) => {
    const height = '38px' // add for 1px border
    const justifyContent = 'center';
    return { ...provided, height, justifyContent};
  },
  control: (provided, state) => {
    const borderColor = state.hasValue ? '#36454D' : '#C7D3D9';
    const borderRadius = '100px';
    const height = '40px' // add for 1px border
    return { ...provided, borderColor, borderRadius, height };
  },
  input: (provided, state) => {
    const height = '38px' // add for 1px border
    return { ...provided, height};
  },
  dropdownIndicator: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    const position = 'absolute';
    const right = '3px';
    return { ...provided, color, position, right};
  },
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  }

}

function OverlayComponent({children, ...props}){
  return (
    <div></div>
  )
}

export const DayPickerAdapter = ({input, meta, dateFormat, items, label, intlLocale, intlMessages, className, disabled, ...rest }) => {
  console.log("DaytPickerAdapter locale ", intlLocale)
  return (
    <StyledDayPickerInputWrapper className={cn(className, {"emptyBorder" : meta.pristine})} isError={(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} emptyBorder={meta.pristine}>
      <StyledLabel>{label}</StyledLabel>
      <StyledDayPickerInput>
        <DayPickerInput
          inputProps = {{
            ...input,
            autoComplete: "off",
            disabled: disabled,
            className:cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}}
          onDayChange={day=> input.onChange(day)}
          formatDate={formatDate}
          parseDate={parseDate}
          format={dateFormat || MOMENT_DATE_FORMAT || "L"}
          dayPickerProps={{
            locale: intlLocale,
            localeUtils: MomentLocaleUtils
          }}
          overlayComponent = {OverlayComponent}
          locale = {intlLocale}
          value = {input.value}
          className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
          placeholder={`${formatDate(new Date(), dateFormat || MOMENT_DATE_FORMAT || "L", intlLocale)}`}
        />
      <StyledDayPickerIconContainer>{/*<SelectChevronDown />*/}</StyledDayPickerIconContainer>
      </StyledDayPickerInput>
      <ErrorLabel
        isError={(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)}
        className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) ? intlMessages["registration.form.date_of_birth.error"] : undefined}
      </ErrorLabel>
    </StyledDayPickerInputWrapper>
  )}

export const SelectAdapterNonClearable = ({input, meta, items, label, type,  intlMessages , className="SelectAdapter", defaultValue, isDisabled, ...rest }) => {
  let newSelectStyles = selectStyles;
  if (isDisabled){
    newSelectStyles = {
      ...newSelectStyles,
      dropdownIndicator: (provided, state) => {
        return { 
          ...provided,
          color: 'lightgray'
        };
      },
      control: (provided, state) => {
        return { 
          ...provided,
          borderColor: 'lightgray',
          borderRadius: '3px',
          height: '53px'
        };
      },
    };
  } 
  if ((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)){
    selectStyles.control['borderColor'] = 'red';
    newSelectStyles = {
      ...selectStyles,
        control: (provided, state) => {
          const borderRadius = '100px';
          const height = '40px' // add for 1px border
          const borderColor = 'red';
          const borderWidth = '2px';
          return { ...provided, borderColor, height, borderRadius, borderWidth};
        }
    }
  }
  return(
  <StyledInputLayout>
    <StyledLabel>{label}</StyledLabel>
    <Select
      {...input}
      styles={newSelectStyles}
      className={ cn(className, {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue.value)}}
      options={items}
      isDisabled={isDisabled}
      isClearable={false}
      isSearchable={false}
      value={find(items, {'value': defaultValue || input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <ErrorLabel
      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
      className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) ? intlMessages["registration.form.location.error"] : undefined}
    </ErrorLabel>
  </StyledInputLayout>
  )
}


export const RegistrationActivation = ({email=null}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initValues = {}
  const formRef = useRef(null);
  const emailRef = useRef();
  const intlMessages = useSelector(getIntlMessages);

  const [ sent, setSent ] = useState(email);

  const requestActivationCode = async (email) => {
    if (email){
      setSent(true)
      // send email to user
      try{
        const user = await services.requestCodeActivation({email});
      } catch (err){
        emailRef.current.focus();
        emailRef.current.blur();
        setSent(false);
      }
    } else {
      emailRef.current.focus();
      emailRef.current.blur();
      setSent(false);
    }
  }

  const handleActivation = async (values) => {
    try {
      const user = await services.activateEmail(values);
      dispatch( { type: authConstants.LOGIN_SUCCESS, data: user});
      history.push('/');
    } catch (err) {
      console.log(err);
      return {
        ...err,
        [FORM_ERROR] : upperFirst("general error")
      }
    }
  }

  if (email){
    initValues['email'] = email;
  }

  return (
    <StyledActivationWrapper>
      <Form
         initialValues={initValues}
         onSubmit={handleActivation}
         render = {({
           submitError,
           formError,
           handleSubmit,
           mutators,
           form,
           reset,
           submitting,
           pristine,
           validating,
           values
         }) => {
           formRef.current = form;
           return (
             <form
               onSubmit={handleSubmit}
              >
                
                <Flex justifyContent={'center'}>
                  <Image src={logo} alt="logo" w={'80x'} />
                </Flex>
                <StyledActivationMessage>{intlMessages['activation.form.description']}</StyledActivationMessage>
                <Field name="email" parse={v => v} validate={required}>
                    {({ input, meta}) => {
                    return (
                      <StyledInputLayout>
                        <StyledInputWithOverlay isSent={sent}>
                          <StyledInput
                            ref={emailRef}
                            {...input}
                            type="text"
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            placeholder={upperFirst(intlMessages['activation.form.email.label'])} />
                          <div onClick={() => (requestActivationCode(input.value))}>{sent ? intlMessages['activation.form.email.sent']: intlMessages['activation.form.email.sendAgain']}</div>
                        </StyledInputWithOverlay>
                        <ErrorLabel
                          setLineHeight={5}
                          isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`activation.form.email.error`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  }
                  </Field>
                  <Field name="code" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledInput
                          {...input}
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          type="text"
                          placeholder={upperFirst(intlMessages['activation.form.code.label'])} />
                        <ErrorLabel
                          setLineHeight={5}
                          isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`activation.form.code.error`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                  <Button
                    variant={'solid'}
                    w={['full']}
                    h={'45px'}
                    isDisabled={
                      submitting ||
                      !(values.email &&
                        values.code
                       )
                    }
                    isLoading={submitting}
                    type="submit">
                    { (submitting)
                      ? <StyledLoadingButtonLoading><LoadingEllipses /></StyledLoadingButtonLoading>
                      : toUpper(intlMessages['activation.form.submit'])
                    }
                  </Button>
            </form>
           )
         }
       } />
    </StyledActivationWrapper>
  )
}

export const RegistrationSignUp = ({submitting, values, isShowing, next, country, handleLINELogin, lineError, lineLoading, setSignUpMethod, submitError}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const nanoid = customAlphabet(
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
    15
  );
  const state = nanoid();
  const lineData = useRef(null);

  // setup LINE
  let fullURL = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    fullURL += `:${window.location.port}`;
  }
  const lineRedirectUri = `${fullURL}/register`

  const saveLocalData = (values) => {
    storage.setRegister(values);
  }

  /** set line data state after LINE redirect and back into our app (saving for phone input popup) */
  const setIdToken = (data) => {
    console.log('setIdToken ', data);
    
    handleLINELogin(data?.code, data?.socialAppId)
    // register should open phone input popup
    // loginType is set by the initial LINE login button parameters
    // then retrieved again through LINE redirect's query parameters
  };

  const termsUrl = () => {
    let countryString = country || "";
    if (countryString.toLowerCase() == "th"){
      return URLS.terms_th.url
    } else if (countryString.toLowerCase() == "jp"){
      return URLS.terms_jp.url
    } else { 
      return URLS.terms_us.url
    }
  }

  const privacyUrl = () => {
    let countryString = country || "";
    if (countryString.toLowerCase() == "th"){
      return URLS.privacy_th.url
    } else if (countryString.toLowerCase() == "jp"){
      return URLS.privacy_jp.url
    } else { 
      return URLS.privacy_us.url
    }
  }

  return(
    <>
      <Flex w={['full']} justifyContent={['center']} textAlign={['center']}>
          {submitError && <ErrorLabel addCSS={'text-align: center;'} isError={true}>{submitError}</ErrorLabel>}
      </Flex>
      <StyledRegistrationSignUpWrapper>
          <StyledRegistrationSignUpColumn>
              <Field name="first_name" parse={v => v} validate={required}>
                  {({ input, meta}) => (
                    <StyledInputLayout>
                      <StyledInput
                        {...input}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={upperFirst(intlMessages['registration.form.first_name.label'])} />
                      <ErrorLabel
                        isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                        >
                          {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && upperFirst(intlMessages['registration.form.first_name.error']) || meta.submitError}
                      </ErrorLabel>
                    </StyledInputLayout>
                  )}
              </Field>
              <Field name="email" parse={v => v}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                    <StyledInput
                      {...input}
                      type="text"
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      placeholder={upperFirst(intlMessages['registration.form.email'])} />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <Field name="password" parse={v => v}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                    <StyledInput
                      {...input}
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      type="password"
                      placeholder={upperFirst(intlMessages['registration.form.password'])} />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <Flex pb={'5px'} px={'5px'}>
                <Text
                  fontSize={'14px'}
                  whiteSpace={'pre'}
                >
                  {intlMessages['registration.form.password.requirements']}
                </Text>
              </Flex>
        <StyledTerms>
          <Field
            name="accept_terms"
            component="input"
            type="checkbox"
            value="yes"
            id="yes_accept_terms"
            />
          <label>{intlMessages['registration.form.accept_terms.label']} <a target="_blank" href={`${termsUrl()}`}>{intlMessages['registration.form.accept_terms.text']}</a></label>

        </StyledTerms>
        <Flex justify={"center"}>
          <Field name="accept_terms">
              {({ meta }) => {
                return (
                  <ErrorSpan
                    setLineHeight={17}
                    isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                    >
                      {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && upperFirst(intlMessages['registration.form.accept_terms.error']) || meta.submitError}
                  </ErrorSpan>
                )
              }}
          </Field>
        </Flex>
        <StyledTerms>
          <Field
            name="accept_privacy"
            component="input"
            type="checkbox"
            value="yes"
            id="yes_accept_privacy"
          />
        <label>{intlMessages['registration.form.accept_terms.label']} <a target="_blank" href={`${privacyUrl()}`}>{intlMessages['registration.form.privacy_policy.text']}</a></label>

        </StyledTerms>
        <Flex justify={"center"}>
        <Field name="accept_privacy">
            {({ meta }) => {
              return (
                <ErrorSpan
                  setLineHeight={17}
                  isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                  >
                    {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && upperFirst(intlMessages['registration.form.accept_privacy.error']) || meta.submitError}
                </ErrorSpan>
              )
            }}
          </Field>
        </Flex>
            <Button
              mt={'30px'}
              variant={"solid"}
              w={'full'}
              h={'45px'}
              type={"submit"}
              //onClick={()=>{setSignUpMethod("EMAIL")}}
              isDisabled={
                !(values.email &&
                  values.password &&
                  (values.accept_terms && values.accept_terms.length > 0) &&
                  (values.accept_privacy && values.accept_privacy.length > 0)
                )
              }
              isLoading={submitting}
            >
              {intlMessages['registration.form.submit']}
            </Button>

          </StyledRegistrationSignUpColumn>
      </StyledRegistrationSignUpWrapper>
    </>
  )
}

export const CountryRegionSelectField = ({country, regions}) => {
  const intlMessages = useSelector(getIntlMessages);
  let items = []
  if (country && regions[country]){
    if (toUpper(country) != "US" ){
      items = [{value: country, label: intlMessages[`settings.countries.${toUpper(country)}.label`]}]
    } else{
      items = [...regions[country].map(item => {
        return { label: item.area, value: item.code }
        }), 
        { label: intlMessages['config.country.OTHER.label'], value: "OTHER"}
      ]
    }
  } else {
    items = [];
  }

  return (
    <Field
      name="location"
      className={'SelectAdapter'}
      isDisabled={toUpper(country) != "US"}
      type='settings'
      validate={required}
      items={items}
      intlMessages={intlMessages}
      label={upperFirst(intlMessages['registration.form.location.label'])}
      component={SelectAdapterNonClearable}
      placeholder={upperFirst(intlMessages['registration.form.location.label'])}
    />
  )
}

export const RegistrationBasicInformation = ({country, setCountry}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const regions = useSelector(getRegions);

  useEffect(()=>{
    if (country){
      dispatch(appsettingsActions.fetchAppSettings('regions', country));
    }
  }, [country])


  const handleSwitchCountry = () => {
    // remove from storage and set country as none
    storage.clearCountry();
    setCountry(null);
  }


  return(
      <React.Fragment>
      <Flex justifyContent={'center'}>
        <Image src={logo} alt="logo" w={'80x'} />
       </Flex>
      <Flex mt={[4]} w={['full']} justifyContent={'center'}>
        <Text mr={2}>{intlMessages[`settings.countries.${country}.label`]}</Text>
        <Link onClick={handleSwitchCountry}>{intlMessages['registration.basicinformation.switchcountry']}</Link>
      </Flex>
    </React.Fragment>
  )
}

const RadioWrapper = (props) => {
  const {
    input,
    country,
    onClickExtra,
    countryCode
  } = props;

  const handleChange = (e) => {
    input.onChange(e);
    onClickExtra(countryCode);
  }

  return (
    <input
      {...input}
      checked={country == countryCode}
      onChange={handleChange}
    />
  );
};

export const RegistrationInitial = ({isShowing, country, setCountry, next}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const countries = useSelector(getCountries);

  const openChat = () => {
    if (window.ChannelIO){
      window.ChannelIO('openChat');
    }
  }

  const onNext = () => {
    next();
    // TODO: for now, disable registration from site.
    // open confirm dialog
    /*let negativeCallbacks = [modalActions.closeModal('confirmDialog')];
    let positiveCallbacs = [
      modalActions.closeModal('confirmDialog', [openChat])
    ]
    dispatch(modalActions.openConfirmDialog(
      intlMessages['registration.dialog.noregistration.header'],
      intlMessages['registration.dialog.noregistration.description'],
      negativeCallbacks,
      positiveCallbacs,
      intlMessages['registration.dialog.noregistration.button.confirm']
    ));*/
  }

  return(
    <InitialWrapper isShowing={isShowing}>
    <Flex justifyContent={'center'}>
      <Image src={logo} alt="logo" w={'80x'} />
    </Flex>
      <BasicInformationTitle>
        {intlMessages['registration.intial.title']}
      </BasicInformationTitle>
      <StyledCountrySelection>
        <FormRadioGroup onChange={(e) => console.log("on change radio group ")}>
          {countries && countries.map(elem =>{
            return {value: elem.id, label: intlMessages[`config.country.${elem.code}.label`], code: elem.code}
          }).map((item) =>
            <FormRadioLabel key={`RadioLabel_Country__${item.value}`}>
              <Field
                  name="country"
                  component={RadioWrapper}
                  type="radio"
                  country={country}
                  value={item.value}
                  onClickExtra={setCountry}
                  countryCode={item.code}
                  key={`Field_Country__${item.value}`}
              />
              <RequestTypeField isSelected={item.code == country}>
                {item.label} {"("}{intlMessages[`settings.countries.${item.code}.label`]}{")"}
              </RequestTypeField>
            </FormRadioLabel>
          )}
        </FormRadioGroup>
      </StyledCountrySelection>
      <StyledIntialActions>
        {/*<StyledRegisterButton
          type="button"
          disabled={isEmpty(country, true)}
          onClick={onNext}
          >
          {intlMessages['registration.initial.button.text']}
        </StyledRegisterButton>*/}
      </StyledIntialActions>
      <StyledExtraDescription>
        <StyledExtraDescriptionLink to={'/login'}>
          {intlMessages['registration.initial.alreadyRegistered.text']}
          <span>{intlMessages['registration.initial.alreadyRegistered.text.span']}</span>
        </StyledExtraDescriptionLink>
      </StyledExtraDescription>
    </InitialWrapper>
  )
}

export const Registration = () =>{
  let dispatch = useDispatch();
  let params = useParams();
  let history = useHistory();
  let [page, setPage] = useState(0)
  let onboardingData = useRef({})
  let loggedinFlag = useSelector(getIsLoggedInFlag);
  let isChatLoaded = useSelector(getIsChatLoaded)
  let formRef = useRef(null);
  let currentEmail = useRef(null);
  let currentSignupMethod = useRef("EMAIL");
  let currentSignupMethodId = useRef();
  let socialCode = useRef();
  let [country, setCountry ] = useState(storage.getCountry() ? storage.getCountry() : null);
  const intlMessages = useSelector(getIntlMessages);

  let [googleLoading, setGoogleLoading ] = useState(false)
  let [facebookLoading, setFacebookLoading ] = useState(false)
  let [googleError , setGoogleError ] = useState();
  let [facebookError, setFacebookError]  = useState();

  useEffect(()=>{
    dispatch(appsettingsActions.fetchAppSettings('countries'));
    return () => {};
  }, [])

  useEffect(()=> {
      if (localStorage.getItem('user')){
        dispatch(actions.getProfile());
      } else {
        console.log("USER LOCAL STORAGE NOT FOUND")
      }
      return () => {};
  }, [loggedinFlag])

  useEffect(()=>{
    
  }, [params])

  const handleRegister = async (values) => {
    console.log("handle regssiter ", values)
    const valuesCopy = cloneDeep(values);
    const convertedValues = convertToAPIValues(valuesCopy)
    if (currentSignupMethod.current == "GOOGLE" || currentSignupMethod.current == "FACEBOOK" || currentSignupMethod.current == "LINE"){
      let { email, password, accept_privacy, accept_terms, ...socialValues} = convertedValues;
      let submitValues = {
        ...socialValues, 
        code: socialCode.current , 
        social_app: currentSignupMethod.current,
        social_app_id: currentSignupMethodId.current
      }
      try {
        const user = await services.registerSocial(submitValues);
        history.push('/');
        storage.clearRegister();
        return null;
      } catch (errors) {
        let {email, code, ...err} = errors;
        if (currentSignupMethod.current == "GOOGLE"){
          if (code){
            setGoogleError(intlMessages['form.error.general.google'])
          }
          if (email && email.length > 0){
            setGoogleError(email[0]);
          }
        } else {
          if (err.code){
            setFacebookError(intlMessages['form.error.general.google'])
          }
          if (email && email.length > 0){
            setFacebookError(email[0]);
          }
        }
        setFacebookLoading(false);
        setGoogleLoading(false);
        return {
          ...err,
          [FORM_ERROR] : upperFirst(intlMessages['form.error.general'])
        }
      }
    } else {
      setFacebookError(false);
      setFacebookLoading(false);
      setGoogleError(false);
      setGoogleLoading(false);
      let { accept_privacy, accept_terms, ...submitValues } = convertedValues;
      try {
        const user = await services.register(submitValues);
        //
        currentEmail.current = submitValues.email;
        history.push('/register/activation');
        return null;
      } catch (err) {
        if (err.password && err.password.length > 0){
          err.password = err.password[0]
        }
        if (err.email && err.email.length > 0){
          err.email = err.email[0]
        }
        return {
          ...err,
          [FORM_ERROR] : upperFirst(intlMessages['form.error.general'])
        }
      }
    }
  }

  const setSignUpMethod = (method) => {
    currentSignupMethod.current = method;
  }

  const handleGoogle = async (response) => {
    //const { intl } = this.props
    setGoogleLoading(true);
    if (response.tokenId){
      currentSignupMethod.current = "GOOGLE";
      socialCode.current = response.tokenId;
      if (formRef.current.getState().invalid){
        setGoogleLoading(false);
        setGoogleError(upperFirst(intlMessages['form.error.emptyform.google']) )
      }
      formRef.current.submit();
    } else {
      setGoogleError(upperFirst(intlMessages['form.error.general.google']) )
      setGoogleLoading(false);
    }
  }

  const handleFacebook = async (response) => {
    setFacebookLoading(true);


    if (response.status == "unknown"){
      setFacebookError(false);
      setFacebookLoading(false);
      return
    }
    if (response.accessToken){
      currentSignupMethod.current = "FACEBOOK";
      socialCode.current = response.accessToken;
      if (formRef.current.getState().invalid){
        setFacebookLoading(false);
        setFacebookError(upperFirst(intlMessages['form.error.emptyform.google']) )
      }
      formRef.current.submit();
    } else {
      setFacebookError(upperFirst(intlMessages['form.error.general.facebook']) )
      setFacebookLoading(false);
    }
  }

  const handleLINELogin = async (code, socialAppId) => {
    currentSignupMethod.current = "LINE";
    currentSignupMethodId.current = socialAppId
    socialCode.current = code;
    if (formRef.current.getState().invalid){
      setFacebookLoading(false);
      setFacebookError(upperFirst(intlMessages['form.error.emptyform.google']) )
    }
    formRef.current.submit();
  };

  const nextPage = () => {
    setPage(oldPage => oldPage + 1)
  }

  const handleCountry = (val) => {
    console.log("handlecountrychange, ", val);
    storage.setCountry(val);
    setCountry(val);
  }

  if (params.pageType == "activation"){
    return (
      <PageWrapper height={'calc(100vh - 80px)'} >
        { isChatLoaded && <Redirect to={"/"}/>}
        <RegistrationActivation email={currentEmail.current} />
      </PageWrapper>
    )
  } else {
    return (
      <PageWrapper>
        { isChatLoaded && <Redirect to={"/"}/>}
        <Form
           initialValues={{
              ...(storage.getRegister()),
              country: country,
              location: toUpper(country) == "US" ? null : country
          }}
           onSubmit={handleRegister}
           validate= {values => {
             const errors = {}
             if (!(values.accept_terms && values.accept_terms.length > 0)){
               errors.accept_terms = 'required'
             }
             if (!(values.accept_privacy && values.accept_privacy.length > 0)){
               errors.accept_privacy = 'required'
             }
             return errors;
           }}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => {
             formRef.current = form;
             return (
               <form
                 onSubmit={handleSubmit}
                >
                 <RegistrationInitial isShowing={!country} country={values.country} setCountry={handleCountry} />

                 <BasicInformationWrapper isShowing={country}>
                   <RegistrationBasicInformation country={country} setCountry={setCountry} />

                   <StyledSpacing>{intlMessages['registration.createaccount.title']}</StyledSpacing>
                   <RegistrationSignUp
                     country={country} 
                     isShowing={page == 1}
                     next={nextPage}
                     values={values}
                     submitting={submitting}
                     handleGoogle={handleGoogle}
                     handleFacebook={handleFacebook}
                     handleLINELogin={handleLINELogin}
                     googleError={googleError}
                     facebookError = {facebookError}
                     googleLoading={googleLoading}
                     facebookLoading={facebookLoading}
                     setSignUpMethod={setSignUpMethod}
                     submitError={submitError}
                    />
                 </BasicInformationWrapper>
              </form>
             )
           }
         } />
      </PageWrapper>
    )
  }

}
